

import DxSelectBox from 'devextreme-vue/select-box';
import { grupos } from '../data/data.js';

export default {
  components: { DxSelectBox },
  props: {
    groupId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dataSource: grupos,
      value: null,
    };
  },
  methods: {
    setDefaultValue(items) {
      const firstItem = items[0];
      if (firstItem && this.value === null) {
        this.value = firstItem.ProductID;
      }
    },
  },
};
